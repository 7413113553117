import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form != undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _vm.$route.name === 'NewController' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új kontroller")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Kontroller szerkesztése")]), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v("Kontroller adatok")]), _c(VCardText, [_c(VTextField, {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Alias",
      "error-messages": _vm.errors.alias,
      "filled": ""
    },
    model: {
      value: _vm.form.alias,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alias", $$v);
      },
      expression: "form.alias"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    on: {
      "change": function () {
        _vm.form.building_level_id = 0;
        _vm.fetchBuildingLevels();
      }
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Épület szint",
      "error-messages": _vm.errors.building_level_id,
      "items": _vm.buildingLevels,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "filled": ""
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Épület típus",
      "error-messages": _vm.errors.building_type,
      "filled": "",
      "placeholder": "B: épület, P: parkoló, BP vagy PB: mindkettő"
    },
    model: {
      value: _vm.form.building_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_type", $$v);
      },
      expression: "form.building_type"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Típus",
      "error-messages": _vm.errors.type_id,
      "items": _vm.types,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type_id", $$v);
      },
      expression: "form.type_id"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "filled": "",
      "mask": _vm.$config.inputMasks.floatNumber
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "filled": "",
      "mask": _vm.$config.inputMasks.floatNumber
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Connection",
      "error-messages": _vm.errors.connection_id,
      "items": _vm.connections,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.connection_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "connection_id", $$v);
      },
      expression: "form.connection_id"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Sorozatszám",
      "error-messages": _vm.errors.serial_number,
      "filled": ""
    },
    model: {
      value: _vm.form.serial_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "serial_number", $$v);
      },
      expression: "form.serial_number"
    }
  }), _c(VSwitch, {
    attrs: {
      "label": "Engedélyezés",
      "error-messages": _vm.errors.enabled,
      "true-value": 1,
      "false-value": 0
    },
    model: {
      value: _vm.form.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "enabled", $$v);
      },
      expression: "form.enabled"
    }
  }), _c(VTextarea, {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }