<template>
  <div v-if="form != undefined">
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="my-6" v-if="$route.name === 'NewController'">Új kontroller</h1>
    <h1 class="my-6" v-else>Kontroller szerkesztése</h1>

    <v-row>
      <v-col :cols="12" :lg="6">
        <v-card>
          <v-card-title>Kontroller adatok</v-card-title>
          <v-card-text>
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-text-field
              label="Alias"
              :error-messages="errors.alias"
              v-model="form.alias"
              filled
            />
            <v-select
              label="Épület"
              :error-messages="errors.building_id"
              v-model="form.building_id"
              :items="buildings"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
              @change="
                () => {
                  form.building_level_id = 0;
                  fetchBuildingLevels();
                }
              "
            />
            <v-select
              label="Épület szint"
              :error-messages="errors.building_level_id"
              v-model="form.building_level_id"
              :items="buildingLevels"
              item-text="name"
              item-value="id"
              :value-comparator="$config.comparators.isEqual"
              filled
            />
            <v-text-field
              label="Épület típus"
              :error-messages="errors.building_type"
              v-model="form.building_type"
              filled
              placeholder="B: épület, P: parkoló, BP vagy PB: mindkettő"
            />
            <v-select
              label="Típus"
              :error-messages="errors.type_id"
              v-model="form.type_id"
              :items="types"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-masked-text-field
              label="X koordináta"
              :error-messages="errors.x_coord"
              v-model="form.x_coord"
              filled
              :mask="$config.inputMasks.floatNumber"
            />
            <v-masked-text-field
              label="Y koordináta"
              :error-messages="errors.y_coord"
              v-model="form.y_coord"
              filled
              :mask="$config.inputMasks.floatNumber"
            />
            <v-select
              label="Connection"
              :error-messages="errors.connection_id"
              v-model="form.connection_id"
              :items="connections"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-masked-text-field
              label="Sorozatszám"
              :error-messages="errors.serial_number"
              v-model="form.serial_number"
              filled
            />
            <v-switch
              label="Engedélyezés"
              :error-messages="errors.enabled"
              v-model="form.enabled"
              :true-value="1"
              :false-value="0"
            />
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      form: {},
      building_types: [
        { name: 'Parkoló', value: 'P' },
        { name: 'Épület', value: 'B' },
        { name: 'Parkoló és Épület', value: 'BP' },
      ],
      errors: {},
      loading: false,
    };
  },

  mounted() {
    this.fetchController();
    // // this.fetchControllerTypes();
    // this.fetchConnections();
    // this.fetchBuildings();
    // this.fetchBuildingLevels();
  },

  methods: {
    async fetchController() {
      this.loading = true;
      try {
        const response = await this.$http.get(`controllers/details/${this.$route.params.id || 0}`);
        this.form = response.controller;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchControllerTypes() {
      const response = await this.$http.get('controller-types/list');
      console.log(response.controller_types);
      this.types = response.types;
    },

    async fetchConnections() {
      const response = await this.$http.get('connections/list');
      this.connections = response.connections;
    },

    async fetchBuildings() {
      const response = await this.$http.get('buildings/list');
      this.buildings = response.buildings;
    },

    async fetchBuildingLevels() {
      const response = await this.$http.post('building-levels/list', {
        building_id: this.form.building_id,
      });
      this.building_levels = response.building_levels;
    },

    async save() {
      this.loading = true;
      this.errors = {};

      try {
        const response = await this.$http.post('controllers/save', this.form);

        this.loading = false;

        if (Object.keys(response.errors).length) {
          return (this.errors = response.errors);
        }
        this.$dialog.notify.success('Sikeres mentés');
        this.$router.replace({ name: 'ControllerList' });
      } catch (e) {
        this.$dialog.notify.error(e);
      }
    },
  },

  asyncComputed: {
    types: {
      async get() {
        const response = await this.$http.get('controller-types/list');
        return response.controller_types;
      },
      default: [],
    },

    connections: {
      async get() {
        const response = await this.$http.get('connections/list');
        return response.connections;
      },
      default: [],
    },

    buildings: {
      async get() {
        const response = await this.$http.get('buildings/list');
        return response.buildings;
      },
      default: [],
    },

    buildingLevels: {
      async get() {
        const response = await this.$http.get('building-levels/list', {
          building_id: this.form.building_id,
        });
        return response.building_levels;
      },
      default: [],
      shouldUpdate () {
        return this.form.building_id > 0;
      }
    },
  },
};
</script>
